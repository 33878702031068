(function($) {
	
	var $block = $('.wp-block-metropolis-scrolling-slideshow');
	
	$block.each(function() {
		
		var $this = $(this),
			resizeTimer,
			$media = $this.children('.wp-block-columns').children('.media'),
			$content = $media.siblings('.content'),
			$mediaSlides = $media.children(),
			$contentSlides = $content.children();
			
		var recalulate = function() {
			var mediaHeight = $media.height();
			$media.css( { top: 'calc(50% - ' + mediaHeight / 2 + 'px' } );
		};
		
		$contentSlides.waypoint({
			handler: function(direction) {
				var idx = jQuery(this.element).index();
				if (direction == 'up') {
					idx = (idx == 0) ? 0 : idx - 1;
				}
				$mediaSlides.removeClass('active').addClass('not-active').eq(idx).toggleClass('not-active active');
				$contentSlides.removeClass('active').addClass('not-active').eq(idx).toggleClass('not-active active');
			},
			offset: '65%',
		});
		
		$contentSlides.addClass('not-active').first().toggleClass('not-active active');
		$mediaSlides.addClass('not-active').first().toggleClass('not-active active');
		recalulate();
		
		$(window).on('resize', function(e) {
			
			clearTimeout(resizeTimer);
			
			resizeTimer = setTimeout(function() {
			
				recalulate();
						
			}, 100);
			
		});
		
		if( $this.hasClass('is-style-background-images') ) {
			
			$container = $media.closest('.wp-block-metropolis-section');
			$overlay = $container.children('.wp-block-metropolis-section__overlay');
			
			$container.css( { clipPath: 'inset(0)' } );
			$media.css( { display: 'grid' } ).append( $overlay );
			$overlay.css( { position: 'fixed' } );
				
		}
		
	});
	
})(jQuery);